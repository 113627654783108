import { useEffect } from "react";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { useUserId } from '@/hooks/useUserId';
import { devIdCookieName, routeCookieName } from '@/constants';
import { getRouteFromQueryParams } from '@/utils/getRouteFromQueryParams';
import { createUser } from '@/utils/tvlUserApis';

export const TvlApiHandler = () => {
	const { userId, setUserId } = useUserId();
	const router = useRouter();

	useEffect(() => {
		if (userId || !router.isReady) return;
		let deviceId = Cookies.get(devIdCookieName);
		const route = getRouteFromQueryParams(router.query) ?? Cookies.get(routeCookieName) as VisitRoute ?? "organic";
		Cookies.set(routeCookieName, route, { expires: 1 });

		if (!deviceId) {
			const oldUserId = Cookies.get('UDCuserID')?.split('_').pop();
			if (oldUserId && /^[0-9a-f]+$/.test(oldUserId)) {
				Cookies.set(devIdCookieName, oldUserId);
				deviceId = oldUserId;

			} else {
				const newId = uuidv4().replace(/-/g, '');
				Cookies.set(devIdCookieName, newId);
				deviceId = newId;
			}
		}

		createUser({
			device_id: deviceId,
			acquired_route: route,
			pseudo_id: Cookies.get("_ga"),
		})
			.then((userId) => {
				setUserId(userId);
			});

	}, [router.isReady]);

	return null;
};
