import dateFormat, { masks } from 'dateformat';

type SkyCarUrlBuildProps = {
	pickupPlace: string;
	dropoffPlace?: string;
	pickupTime: string | Date;
	dropoffTime: string | Date;
	driverAge?: number;
	currencyCode?: string;
	countryCode?: string;
	languageCode?: string;
	label?: string;
};
/**
 * Builds Skyscanner car hire url from the given params.
 * @returns Generated URL object
 */
export function buildSkyCarUrl({ pickupPlace, dropoffPlace, pickupTime, dropoffTime, driverAge, currencyCode, countryCode, languageCode, label }: SkyCarUrlBuildProps) {
	if (!pickupPlace)
		throw new Error('pickupPlace is required');
	if (!pickupTime)
		throw new Error('pickupTime is required');
	if (!dropoffTime)
		throw new Error('dropoffTime is required');
	masks.sky = "yyyy-mm-dd'T'HH:'00'";

	let url = new URL('/g/referrals/v1/cars/day-view', 'https://www.skyscanner.net');
	url.searchParams.append('mediaPartnerId', '4367185');
	if (countryCode) url.searchParams.append('market', countryCode.toUpperCase());
	if (languageCode) url.searchParams.append('locale', languageCode);
	if (currencyCode) url.searchParams.append('currency', currencyCode.toUpperCase());
	if (label) url.searchParams.append('utm_term', label);

	url.searchParams.append('pickupPlace', pickupPlace);
	url.searchParams.append('pickupTime', dateFormat(pickupTime, "sky"));
	url.searchParams.append('dropoffTime', dateFormat(dropoffTime, "sky"));
	if (dropoffPlace) url.searchParams.append('dropoffPlace', dropoffPlace);
	if (driverAge) url.searchParams.append('driverAge', driverAge.toString());

	return url;
}
