import axios from "axios";
import { observer } from "mobx-react-lite";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";

import { airportStore, dropoffStore } from "@/stores";
import { ColorRing } from "react-loader-spinner";
import { usePickup } from "@/hooks/usePickup";

function SearchInputBox({
  initialValue,
  id,
  tag = "search_input_box",
  locale = "en",
  logAnalyticsEvent,
  dropOff,
  type,
  inputName,
  setSelectedValue,
  setLocationId,
  locationName,
  setSelectedSearch,
}: {
  initialValue?: string;
  id: string;
  tag: string;
  locale: string;
  logAnalyticsEvent: Function;
  dropOff: boolean;
  type?: string;
  inputName: string;
  setSelectedValue?: Function;
  setLocationId?: Function;
  locationName?: string;
  setSelectedSearch: Function;
}) {
  const [loaderState, setLoaderState] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(false);

  const setPickup = (
    locationName: string,
    id: string,
    cityshortname: string
  ) => {
    airportStore.setDisplayName(locationName);
    airportStore.setCode(id);
    airportStore.setCitynamesshort(cityshortname);
  };

  const setDropoff = (
    locationName: string,
    id: string,
    cityshortname: string
  ) => {
    dropoffStore.setDisplayName(locationName);
    dropoffStore.setCode(id);
    dropoffStore.setCitynamesshort(cityshortname);
  };

  const [places, setPlaces] = useState([]);
  const { setPickupLocation } = usePickup();

  const [filter, setFilter] = useState(
    type === "airport"
      ? dropOff
        ? dropoffStore.displayName
        : airportStore.displayName
      : undefined
  );

  const [debouncedFilter] = useDebounce(filter, 300);
  const [active, setActive] = useState(false);

  const placesQuery = useQuery(
    ["places", debouncedFilter],
    async () =>
      axios.get(
        `https://www.kayak.com/mv/marvel?f=j&s=81&v=v1&where=${debouncedFilter}&lc=${locale}&lc_cc=${locale}`
      ),
    { enabled: debouncedFilter !== undefined }
  );

  useEffect(() => {
    if (type === "airport") {
      if (dropOff) {
      } else {
        setFilter(airportStore.displayName || locationName);
      }
    }
  }, [airportStore, dropOff, type]);

  useEffect(() => {
    if (placesQuery.isSuccess) {
      setPlaces(placesQuery.data.data);
    }
  }, [placesQuery.data]);

  const handleLoader = () => {
    setLoaderState(true);
    const timer = setTimeout(() => {
      setLoaderState(false);
    }, 500);
    return () => clearTimeout(timer);
  };

  return (
    <div
      className={`flex justify-start items-center w-full h-full overflow-clip`}
      id={id}
    >
      <div className="flex gap-1 justify-start items-center w-full transition group">
        <Image
          src="/hero/location-small.svg"
          alt="location pin icon"
          height={24}
          width={24}
        />
        <input
          required={true}
          autoComplete="off"
          type="text"
          name={inputName}
          onFocus={() => {
            id === "pickup_input_box"
              ? setSelectedSearch(1)
              : setSelectedSearch(2);
          }}
          value={
            filter === undefined
              ? dropOff
                ? dropoffStore.displayName
                : airportStore.displayName
              : filter
          }
          onChange={(event) => {
            setFilter(event.target.value);
            handleLoader();
            dropOff ? dropoffStore.clear() : airportStore.clear();
          }}
          onMouseUp={(e) => e.preventDefault()}
          onClick={(event: any) => {
            event.target.select();
            logAnalyticsEvent(tag.concat("_clicked"), {});
            setDisplayOptions(true);
          }}
          onBlur={() => setDisplayOptions(false)}
          placeholder={`${dropOff ? "Enter drop-off location" : "Enter pick-up location"
            }`}
          className="px-0 w-full text-base font-normal truncate border-none ring-none focus:ring-0 focus:border-none bg-inherit focus:outline-none placeholder:text-base lg:placehdoler:text-lg lg:text-lg"
        />
        {loaderState && (
          <div className={`${tag === "dropoff_location" && `pr-6`}`}>
            <ColorRing
              colors={["#0057ff", "#0057ff", "#0057ff", "#0057ff", "#0057ff"]}
              height={40}
              width={40}
            />
          </div>
        )}
      </div>
      {displayOptions && places?.length !== 0 && (
        <ul
          className={`absolute  z-[1000] ${dropOff ? "left-0 top-[65px]" : "left-0 top-[70px]"
            } lg:max-w-[550px]  
                     flex max-h-[320px]  flex-col items-stretch overflow-hidden overflow-y-scroll scrollbar-none rounded-lg border shadow-lg md:max-h-[480px]`}
        >
          {places.map((option: any) => {
            return (
              <li
                onMouseDown={() => {
                  if (!dropOff) setPickupLocation(option);
                  setSelectedSearch(0);
                  setFilter(option.smartyDisplay);
                  logAnalyticsEvent(tag.concat("_selected"), {
                    page_title: document.title,
                    [tag]: option.smartyDisplay,
                  });
                  dropOff
                    ? setDropoff(
                      option.displayname,
                      option.apicode,
                      option.citynameshort
                    )
                    : setPickup(
                      option.displayname,
                      option.apicode,
                      option.citynameshort
                    );
                  setDisplayOptions(false);
                }}
                key={option.id}
                className="flex px-4 py-4 space-x-4 bg-white cursor-pointer hover:bg-background-400"
              >
                <div className="flex justify-center items-center">
                  <SearchIcon iconType={option.displayType.type} />
                </div>
                <div className="flex flex-col items-start">
                  <span className="font-medium">{option?.smartyDisplay}</span>
                  <span className="mt-2 text-sm">
                    {`${option?.displayType.displayName} - ${option?.locationname}`}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default observer(SearchInputBox);

function SearchIcon({ iconType }: { iconType: string; }) {
  if (iconType === "city") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="26"
        fill="none"
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1.5 24.25h25"
        ></path>
        <path
          fill="#000"
          d="M9.25 11.75h-5C3.56 11.75 3 12.31 3 13v10c0 .69.56 1.25 1.25 1.25h5c.69 0 1.25-.56 1.25-1.25V13c0-.69-.56-1.25-1.25-1.25zM23.75.5h-10c-.69 0-1.25.56-1.25 1.25V23c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V1.75C25 1.06 24.44.5 23.75.5z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17.5 18.005H20M6.75 15.5v5m10.75-8.12H20m-2.5-5.625H20"
        ></path>
      </svg>
    );
  } else if (iconType === "airport") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="none"
      >
        <path
          fill="#000"
          d="M26.85 17.125l-9.975-5.875V4.375A1.872 1.872 0 0015 2.5a1.872 1.872 0 00-1.875 1.875v6.875L3.15 17.125c-.4.225-.65.663-.65 1.125 0 .875.837 1.5 1.675 1.262l8.95-2.625v6.863L10.3 25.438a.634.634 0 00-.3.537v.725c0 .413.387.713.775.613l3.65-.913.575-.15.475.113.525.137 2.375.6.837.212c.4.1.776-.2.776-.612v-.725a.619.619 0 00-.3-.538l-2.813-1.687v-6.875l8.95 2.625a1.301 1.301 0 001.675-1.25c0-.462-.25-.9-.65-1.125z"
        ></path>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="26" fill="none">
      <path
        fill="#000"
        d="M10 .5a10 10 0 00-10 10c0 2.365.502 3.912 1.875 5.625L10 25.5l8.125-9.375C19.497 14.412 20 12.865 20 10.5a10 10 0 00-10-10z"
      ></path>
      <path
        fill="#fff"
        d="M10 14.25a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
      ></path>
    </svg>
  );
}
