import dateFormat from "dateformat";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import getURL from "@/utils/getUrl";
import { useUserId } from '@/hooks/useUserId';
import { getRoute } from '@/utils/getRoute';

type Props = {
  img: string;
  location: string;
  price: number;
  code: string;
  locale: string;
};

const PlaceCard = ({ img, location, price, code }: Props) => {
  const router = useRouter();
  const [startDate, setStartDate] = useState(new Date());
  const initialEndDate = new Date(startDate);
  initialEndDate.setDate(startDate.getDate() + 1);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [region, setRegion] = useState("");
  const [userId, setUserId] = useState("");
  const [clickId, setClickId] = useState("anon");
  const { userId: tvlUserId } = useUserId();

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem("userId");
    setUserId(userIdFromStorage || "");
  }, []);

  useEffect(() => {
    if (!router.isReady) return;

    const regionCookie = Cookies.get("region");
    regionCookie && setRegion(regionCookie?.toLowerCase());
  }, [router.isReady]);

  const generateKayakUrl = (location: string) => {
    const startDateWithTime = startDate;
    const endDateWithTime = endDate;
    startDateWithTime.setTime(startDate.getTime());
    endDateWithTime.setTime(endDate.getTime() + 60 * 60 * 1000);
    const kayakURL = `https://${getURL(region)}/in?` +
      'a=kan_187653_561320&' +
      'encoder=27_1&' +
      `enc_cid=${tvlUserId ?? `d1wc_c_${userId.split("_").join(`_${getRoute(router.query)}_`)}`}&` +
      'enc_pid=r80_lp1_deeplinks&' +
      `enc_eid=${clickId}&` +
      `url=/cars/${location === "" ? "NYC" : location}/` +
      `${dateFormat(startDateWithTime, "yyyy-mm-dd")}/${dateFormat(endDateWithTime, "yyyy-mm-dd")}`;
    return kayakURL;
  };

  return (
    <Link href={generateKayakUrl(code)} target="_blank">
      <div className="relative overflow-clip w-fit rounded-[20px]">
        <Image
          src={img}
          height={230}
          width={230}
          alt="place name"
          className="rounded-[20px]"
        />
        <div className="absolute z-10 destinations-near-card-gradient w-full h-full bottom-0 left-0 rounded-b-[20px] opacity-70 lg:opacity-100"></div>
        <div className="absolute z-20 bottom-[19px] left-[17px] text-white">
          <h3 className="font-semibold text-[16px] lg:text-xl">{location}</h3>
          <p className="text-[14px] lg:text-base font-normal">
            Starting from{" "}
            <span className="text-[#F2BE22] font-semibold">
              {price?.toString()}$
            </span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default PlaceCard;
