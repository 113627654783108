// package imports
import dynamic from "next/dynamic";
import Head from "next/head";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";

// local imports
// import { connectToDatabase } from "@/db/connectDb";
import { locales } from "@/locales";
import { findLocale } from "@/locales";
import { localeStore } from "@/stores";
import { connectToCarDataDB } from "@/db/connectCarData";

// components
import Footer from "@/components/layout/footer";
import SearchBox from "@/components/common/search-box";
import RentalPartners from "@/components/common/search-box/RentalPartners";
import CookieBanner from "@/components/common/cookie-banner";
import shuffleArray from "@/utils/shuffle";
import { connectToDatabase } from "@/db/connectDb";

// Dynamic component imports. They will only be loaded when they are rendered.
const DynamicRentServesYou = dynamic(
  () => import("@/components/common/rent80-serves-you"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const DynamicDestinationsNearYou = dynamic(
  () => import("@/components/home/destinations-near"),
  {
    loading: () => <p>Loading...</p>,
  }
);
const DynamicPopularDestinations = dynamic(
  () => import("@/components/home/popular-destinations"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const DynamicMostPopularDestination = dynamic(
  () => import("@/components/home/most-popular-destination"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const DynamicBestCarRental = dynamic(
  () => import("@/components/home/best-car-rental"),
  {
    loading: () => <p>Loading...</p>,
  }
);
const DynamicSecondaryNewsletter = dynamic(
  () => import("@/components/home/secondary-newsletter"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const DynamicRentalCompanyList = dynamic(
  () => import("@/components/home/rental-company-list"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const DynamicFAQ = dynamic(() => import("@/components/common/faq"), {
  loading: () => <p>Loading...</p>,
});

const DynamicBestDeals = dynamic(() => import("@/components/home/best-deals"), {
  loading: () => <p>Loading...</p>,
});

const DynamicReviews = dynamic(() => import("@/components/home/review"));

const DynamicDownloadApp = dynamic(
  () => import("@/components/common/download-app")
);

const DynamicNewsletter = dynamic(
  () => import("@/components/common/newsletter")
);

function Home({ sid, locale, data }: any) {
  const [cookieVisible, setCookieVisible] = useState(false);
  useEffect(() => {
    if (Cookies.get("cookieConsent") != "true") {
      setCookieVisible(true);
    }
  }, []);

  useEffect(() => {
    const localeData = findLocale(locale);
    localeStore.setLocale(localeData);
  }, []);

  return (
    <>
      <Head>
        <title>{`Rent80 - Find the Cheap Car Rental Deals ${locale !== "en" ? `in ${findLocale(locale).name}` : "and Rental Cars."
          }`}</title>
        <meta
          name="description"
          content="Rent80 compare the rental car prices from all major car rental providers such as sixt, avis, dollar, budget and kayak. Rent80 great selection and prices on rental cars."
        />
        <link rel="alternate" href="https://www.rent80.com" hrefLang="en-US" />
        {locales.map((data) => (
          <link
            rel="alternate"
            href={`https://www.rent80.com/${data.code}`}
            hrefLang={data.code}
            key={data.code}
          />
        ))}
        <link rel="image_src" href="/og-image.png" />
        <meta
          property="og:image"
          content="https://www.rent80.com/og-image.png"
        />
        <meta
          property="og:title"
          content={`Rent80 - Find the cheap car rental deals ${locale !== "en"
              ? `in ${findLocale(locale).name}`
              : "and rental cars."
            }`}
        />
        <meta
          property="og:description"
          content={`Rent80 compare the rental car prices from all major car rental providers such as sixt, avis, dollar, budget and kayak. Rent80 great selection and prices on rental cars.`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Rent80" />
        <meta property="og:url" content={`https://www.rent80.com`} />
        <link
          rel="alternate"
          href="https://www.rent80.com"
          hrefLang="x-default"
        />
        <link
          rel="canonical"
          href={`https://www.rent80.com${locale === "en" ? "" : `/${locale}`}`}
        />
      </Head>
      <main className="bg-[#F1F2F7] min-h-screen w-full overflow-x-clip relative">
        <SearchBox sid={sid} locale={locale} type={"home"} />
        <RentalPartners />
        {(locale === "en" || locale === "en-US") && (
          <DynamicBestCarRental locations={JSON.parse(data) || []} />
        )}
        <DynamicRentServesYou />
        {(locale === "en" || locale === "en-US") && (
          <DynamicDestinationsNearYou locale={locale} />
        )}

        <DynamicNewsletter />
        <DynamicDownloadApp />
        {(locale === "en" || locale === "en-US") && (
          <DynamicMostPopularDestination locale={locale} />
        )}
        <DynamicBestDeals />
        <DynamicSecondaryNewsletter />
        <DynamicFAQ type="" />
        <DynamicRentalCompanyList />
        <DynamicReviews />
        <Footer />
        {cookieVisible && (
          <CookieBanner
            onClick={() => {
              setCookieVisible(false);
              Cookies.set("cookieConsent", "true");
            }}
          />
        )}
      </main>
    </>
  );
}

export default observer(Home);

export async function getStaticProps({ locale }: any) {
  const { carDB } = await connectToCarDataDB();
  const { db } = await connectToDatabase();
  const locationsDataMongoFull = await db
    // .collection("destinations")
    .collection("destinations")

    .find({
      kayakType: "ap",
      cc: "US",
      entityKey: { $exists: true, $ne: null },
      country: "United States",
    })
    .project({
      entityKey: 1,
      location: 1,
      ap: 1,
      cityonly: 1,
      id: 1,
      region: 1,
      cc: 1,
      ctid: 1,
      _id: 0,
    })
    .toArray();

  const ctidArray = locationsDataMongoFull.map((item: any) =>
    item.ctid.toString()
  );
  const validCtidArr = await carDB
    .collection("parseddatas")
    .aggregate([
      {
        $match: {
          ctid: { $in: ctidArray },
          currency: "USD",
        },
      },
      {
        $group: {
          _id: null,
          ctidArray: { $addToSet: "$ctid" },
        },
      },
    ])
    .toArray();
  const validCtid = validCtidArr[0].ctidArray;
  const locationsDataMongo = locationsDataMongoFull.filter(
    (item: any) =>
      ctidArray.includes(item.ctid.toString()) &&
      validCtid.includes(item.ctid.toString())
  );
  return {
    props: {
      sid: "sid",
      locale: locale,
      data: JSON.stringify(shuffleArray(locationsDataMongo.slice(0, 39))) || [],
    },
    // revalidate: 21600,
  };
}
