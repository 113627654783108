import { ParsedUrlQuery } from 'querystring';

export const getRoute = (query: { [k: string]: string; } | ParsedUrlQuery) => {
  if (Array.isArray(query.gclid) ? query.gclid.pop() : query.gclid) {
    return "a";

  } else if (Array.isArray(query.msclkid) ? query.msclkid.pop() : query.msclkid) {
    return "b";

  } else if (Array.isArray(query.fbclid) ? query.fbclid.pop() : query.fbclid) {
    return "f";

  } else if (Array.isArray(query.adid) ? query.adid.pop() : query.adid) {
    return "s";

  } else {
    return "o";
  }
};