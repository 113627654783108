import axios from "axios";

export async function createUser(params: Partial<Omit<UserCreateParams, "app" | "device_id_type">>) {
	const tvlUserAPIUrl = process.env.NEXT_PUBLIC_TVL_USER_API_URL;
	if (!tvlUserAPIUrl) throw new Error("TVL_USER_API_URL not set");

	const { data } = await axios.post<{ user_id: number; }>("/api/v1/users/add/", {
		app: "d1_web_car",
		device_id: params.device_id,
		device_id_type: 'browser',
		acquired_route: params.acquired_route,
		pseudo_id: params.pseudo_id
	} as UserCreateParams, {
		baseURL: tvlUserAPIUrl
	});

	return data.user_id;
}

export async function startUserSession(params: Partial<UserSessionStartParams>) {
	const tvlUserAPIUrl = process.env.NEXT_PUBLIC_TVL_USER_API_URL;
	if (!tvlUserAPIUrl) throw new Error("TVL_USER_API_URL not set");

	await axios.post<{ user_id: string; }>("/api/v1/users/session/", {
		user_id: params.user_id,
		tag: params.tag,
		route: params.route ?? "organic",
		vertical: params.vertical ?? "flight",
		country_code: params.country_code,
		gclid: params.gclid,
		fbclid: params.fbclid,
		msclkid: params.msclkid
	} as UserSessionStartParams, {
		baseURL: tvlUserAPIUrl
	})
		.catch(err => { console.error(err); });
} 
