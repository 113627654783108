import { useEffect } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { observer } from 'mobx-react-lite';
import { popupStore } from '@/stores';


const Toast = () => {
    let countDownTimer: NodeJS.Timeout;

    useEffect(() => {
        if (popupStore.message !== "")
            countDownTimer = setTimeout(() => {
                popupStore.setContent("", false, 0);
            }, popupStore?.duration ?? 5000);
        return () => { clearTimeout(countDownTimer); };
    });

    if (popupStore.message === "") {
        return <></>;
    } else {
        return (
            <div className='fixed inset-x-0 bottom-5 z-50'>
                <div className={classNames(
                    'flex mx-auto px-4 py-2 w-fit rounded-lg justify-between text-white gap-3 shadow-2xl shadow-black',
                    (popupStore.error ? 'bg-red-500' : 'bg-green-500')
                )}>
                    <div>{popupStore.message}</div>
                    <button
                        className='px-1'
                        onClick={() => {
                            popupStore.setContent("", false, 0);
                            clearTimeout(countDownTimer);
                        }}
                    >
                        <Image
                            src={'/toast/close.svg'}
                            alt={'Close button'}
                            height={15}
                            width={15}
                        />
                    </button>
                </div>
            </div>
        );
    }
};

export default observer(Toast);