import { create } from "zustand";

type UserIdState = {
  userId: number | undefined;
  setUserId: (userId: number) => void;
};

export const useUserId = create<UserIdState>(
  (set) => ({
    userId: undefined,
    setUserId: (userId) => set({ userId }),
  })
);
