export const returnRouteName = (routeKey: RouteKey) => {
  switch (routeKey) {
    case "a":
      return "ads";
    case "b":
      return "bing";
    case "f":
      return "facebook";
    case "s":
      return "apple";
    case "o":
      return "organic";
    default:
      return "unknown";
  }
};
