// @ts-nocheck

// Importing global styles
import "../styles/globals.css";

// Importing types and libraries
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query"; // React Query for data fetching
import { ReactQueryDevtools } from "react-query/devtools"; // Devtools for React Query
import { NextIntlClientProvider } from "next-intl"; // i18n provider for Next.js
import { useEffect } from "react";
import TagManager from "react-gtm-module"; // Google Tag Manager
// import { RecoilRoot } from 'recoil' // Recoil for state management
import Toast from "@/components/common/toast"; // A common Toast component for notifications
import { analytics } from "@/utils/firebase";
import { useRouter } from "next/router";
import { TvlApiHandler } from '@/components/common/tvl-api-handler';
import Script from 'next/script';

// Create a new query client instance
const queryClient = new QueryClient();

// This is the custom App component. All pages in the app are initialized with it.
const MyApp = ({ Component, pageProps }: AppProps) => {
  // This hook runs once on component mount
  useEffect(() => {
    // Initialize Google Tag Manager with your GTM ID.
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER_ID || "GTM-M67GRTW",
    });
    analytics;

    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "akg373id40");
  }, []);

  const router = useRouter();

  return (
    <>
      {/* Provide the query client to the React Query provider. */}
      <QueryClientProvider client={queryClient}>

        {/* Twitter Integration */}
        <Script
          id="twitter_integration"
          dangerouslySetInnerHTML={{
            __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','p0eup');`
          }}
        />

        {/* Tiktok Integration */}
        <Script
          id="tiktok_integration"
          dangerouslySetInnerHTML={{
            __html: `!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

  ttq.load('CU4C53RC77U28FQ29SH0');
  ttq.page();
}(window, document, 'ttq');`
          }}
        />

        {/* Facebook Integration */}
        <Script
          id="facebook_integration"
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '{7220973421356774}');
  fbq('track', 'PageView');`
          }}
        />

        {/* Provide i18n messages to the NextIntlProvider */}
        <NextIntlClientProvider
          messages={pageProps.messages}
          locale={router.locale}
          timeZone="Europe/Vienna"
        >
          {/* Provide a RecoilRoot for state management */}
          {/* <RecoilRoot> */}
          {/* Render the current page */}
          <Component {...pageProps} />
          {/* Render the Toast component */}
          <Toast />
          {/* Travel user API handler */}
          <TvlApiHandler />
          {/* </RecoilRoot> */}
        </NextIntlClientProvider>
        {/* Enable the React Query devtools */}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
